.featured-objects {
  margin: 20px auto;
  display: block;
  width: 92% !important;
}

.featured-objects-item {
  display: inline-block;
  margin: 10px auto;
  width: 50%;
  min-height: 100px;
}

.featured-objects-item-header {
  text-align: center;
  padding: 10px;
}

.featured-objects-icon {
  width: 80px;
  height: auto;
  margin: auto;
}

@media only screen and (min-width: 40rem) {
  .featured-objects {
    display: block;
    margin: 0;
    width: 100% !important;
    text-align: center;
  }

  .featured-objects-item {
    width: 80px !important;
    display: inline-block;
    margin: 10px 20px;
  }

  .featured-objects-icon {
    width: 60px;
  }

  .featured-objects-icon:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
