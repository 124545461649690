.cover {
  width: 100vw;
  margin: auto;
  text-align: center;
}

.card-preview-container {
  width: 100%;
}
.card-preview {
  display: block;
  max-width: 100%;

  margin: 30px;

  text-align: start;
}

.card-preview-image-container {
  margin: auto;
  height: auto;
  width: auto;
  position: relative;
}
.card-preview img {
  width: 100%;
  height: auto;
}

.card-preview-title {
  padding: 10px;
  position: absolute;
  color: #ffffff;
  font-weight: 900;
  font-size: 1.3rem;
  text-shadow: 1px 1px 1px rgb(56, 56, 56);
  vertical-align: bottom;
  height: 60px;
  bottom: 10px;
  z-index: 100000;
}
.card-preview-text {
  height: 100px;
  overflow: hidden;
}

.card-link {
  color: blue !important;
}

.card-preview-footer {
  position: absolute;
  bottom: 13px;
  left: 26%;
}

.offer-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
}

.offer-container > div img {
  width: 100%;
  height: auto;
}

.offer-container__item2 {
  margin-top: 20px;
}

.featured-objects {
  margin: 20px auto;
  display: block;
  width: 92% !important;
}

.featured-objects-item {
  display: inline-block;
  margin: 10px auto;
  width: 50%;
  min-height: 100px;
}

.featured-objects-item-header {
  text-align: center;
  padding: 10px;
}

.featured-objects-icon {
  width: 80px;
  height: auto;
  margin: auto;
}

.links-to-content {
  width: 100%;
}

.links-to-content hr {
  color: #868686;
}

.links-to-content div {
  font-size: 1.4rem;
}

.link-to-content__item1 {
  width: 90%;
  margin: auto;
}

.link-to-content__item1 i {
  color: #46b066;
}

.link-to-content__item2 {
  width: 90%;
  margin: auto;
}

.link-to-content__item2 i {
  color: #4687b0;
}

@media only screen and (min-width: 40rem) {
  .cover {
    max-width: 1200px;
    margin: auto;
  }
  .card-preview-container {
    width: 1000px;
    display: grid;
    margin: 10px auto !important;
    text-align: center;
    grid-template-columns: auto auto auto;
  }
  .card-preview {
    display: inline-block;
    vertical-align: top;
    max-width: 300px;
    min-width: 280px;
    margin: 0 30px;
    height: 290px;
  }

  .card-preview-image-container {
    margin: auto;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  .card-preview img {
    height: 200px;
    width: auto;
  }

  .card-preview img:hover {
    transform: scale(1.3);
    transition: width 2s linear 1s;
  }

  .card-preview-text {
    padding-top: 10px;
    height: 100px;
    overflow: hidden;
  }

  .card-preview-text a {
    font-weight: bold;
  }

  .card-preview-footer {
    position: absolute;
    bottom: 13px;
    left: 22%;
  }
  .offer-container {
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    vertical-align: top;
  }

  .offer-container > div {
    height: 255px;
    width: 340px;
    position: relative;
    vertical-align: top;
  }

  .offer-container > div img {
    width: 100%;
    height: auto;
  }

  .offer-container__item2 {
    margin-left: 30px;
    margin-top: 0;
  }

  .offer-container__item1 header {
    position: absolute;
    width: 320px;
    bottom: 15px;
    left: 35px;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000000;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
  }

  .offer-container__item2 header {
    position: absolute;
    width: 320px;
    bottom: 15px;
    left: 25px;
    font-size: 1.25rem;
    font-weight: bold;
    color: #000000;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 1);
  }

  .featured-objects {
    display: block;
    margin: 0;
    width: 100% !important;
    text-align: center;
  }

  .featured-objects-item {
    width: 80px !important;
    display: inline-block;
    margin: 10px 20px;
  }

  .featured-objects-icon {
    width: 60px;
  }

  .featured-objects-icon:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  .link-to-content__item1 {
    width: 550px;
    margin: auto;
  }

  .link-to-content__item2 {
    width: 550px;
    margin: auto;
  }
}
