* {
  box-sizing: border-box;
}

html,
body {
  font-family: Sylfaen, sans-serif;
  font-size: 0.95rem;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: black;
}

.cursor-pointer {
  cursor: pointer;
}

.dropdown-button {
  background-color: transparent;
  color: inherit;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content{
    display: none;
    position: absolute;
    background-color: inherit;
    min-width: 200px;
    overflow: auto;
    z-index: 10;
    top: 60px;
}

.dropdown-content a {
    display: block;
    padding: 7px;
    width: 300px;
    text-align: left;
    color: inherit;
}

.dropdown-content a:hover{
    background-color: rgb(47, 211, 85);
}

.show{
    display: block;
}

.items-sun {
  height: 20px !important;
  width: 20px !important;
}

.text-title {
  font-weight: bold;
  font-size: 0.95rem;
}

.title {
  text-transform: uppercase;
  font-family: bpgNinoMtavruli;
}
