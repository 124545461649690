.container{  
  
  margin: auto;
  text-align: center;
  padding: 10px;
}

.container img {
  height: 70px;
  width: auto;
  margin-right: 20px;
}

.container img:hover {
  opacity: 0.5;
  cursor: pointer;
}