.container{
    padding: 10px;
    text-align: center;
}

option[default]{
    color: #ccc;
}

@media only screen and (min-width: 40rem){
    .container{
        padding: 10px;
        text-align: center;
        white-space: nowrap;
    }
}