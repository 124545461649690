.footer{
    display: flex;
    min-height: 200px;
    background-color: #28a745;
    color: white;
    padding: 20px;
    justify-content: left;    
}

.footer ul {
    list-style: none;
    line-height: 1.4;
    font-size: 1rem;
}
.footer a,
.footer a:hover{
    color: white;
}

.footer a:hover{
    background-color: rgb(47, 211, 85);
    padding: 4px 0.5px;
}