.loading-container {
    position: fixed;    
    
    height: 1.6rem;
    z-index: 111;    
    top: 0;
    right: 0;    
    left: 0;
    text-align: center;
    background-color: transparent;
    opacity: 0.8;
    padding-top: 1.6rem;
}

.loading-content {    
    margin: 0;
    color: #198352;
    font-size: 1.2rem;
    font-family: sans-serif;
}