.block {
	display: block !important;
}

.flex {
	display: flex;
}

.inline {
	display: inline !important;
}

.inline-block {
	display: inline-block !important;
}

.visible{
    display: block !important;
}

.visible-visibility{
    visibility: visible !important;
}

.visible-flex{
    display: flex !important;
	transition: 2s ease-in-out;
}
