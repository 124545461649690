.root-container {
  margin: 0;
  padding: 0;
  height: 100vh;
  
}

.header {
  margin: 0;
  padding: 0;
}

.footer {
  margin: 0;
  padding: 0;
}

.main-container {
  padding-top: 10px;
  padding-bottom: 30px;
  min-height: 600px;
  display: block;
}

@media only screen and (min-width: 40rem) {
  .main-container {
    min-height: 100%;
    
  }
}
