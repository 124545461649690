.container {
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

.logo {
  max-height: 100px;
  width: auto;
  align-self: center;
}

.logo img {
  height: 100%;
}

.main {
  margin: 0;
}

.main .top-wrapper .actions {
  padding: 3px;
  margin: 10px auto;
  max-width: 98%;
  border: 1px solid #ccc;
}

.main .top-wrapper .actions .search-input {
  border: 0 !important;
  outline: none;
}

.main .top-wrapper .actions .search-input:focus{
  box-shadow: inset 0 -1px 0 #fff;
}

.main .top-wrapper .nav-links {
  padding: 10px 20px;
}

.main .top-wrapper .nav-links .button {
  padding: 15px;
  font-size: 1.1rem;
  border: 0;
}

.main .top-wrapper .section3 {
  padding: 10px 20px;
}

.main .bottom-wrapper {
  color: white;
  display: flex;
  flex-direction: column;
}

.main .bottom-wrapper .nav-menu-block {
  background-color: #198352;
  color: white;
  font-size: 1.1rem;
  border: white;
  text-align: center;

  margin-bottom: 3px;
  padding: 7px;
  border-radius: 0;
}

.main .bottom-wrapper .nav-menu-block:hover {
  /* background-color: rgb(47, 211, 85); */
  color: #ffffff;
}

.main .bottom-wrapper .nav-menu-block .item1 {
  max-width: 300px;
}

.main .bottom-wrapper .nav-end-logo {
  max-height: 100px;
  align-self: center;
}

.main .bottom-wrapper .nav-end-logo.logo1 {
  width: 270px;
}

.main .nav-end-logo.logo1 img {
  width: 100%;
}

@media screen and (min-width: 46rem) {
  .container {
    text-align: center;

    flex-direction: row;
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  .logo {
    height: 130px;
    margin-right: 10px;
    margin-left: 10px;
  }

  .logo img {
    height: 100%;
  }

  .main {
    margin: 0;
  }

  .main .top-wrapper {
    display: flex;
    flex-direction: row;
  }

  

  .main .top-wrapper .nav-links {
    flex-grow: 1;
    padding: 7px;
    text-align: start;
    padding-left: 30px;
    align-self: center;
  }

  .main .top-wrapper .nav-links .button {
    padding: 15px;
    font-size: 1.1rem;
    border: 0;
    border-right: 1px solid #ccc;
  }

  .main .top-wrapper .nav-links .button.end {
    border-right: 0;
  }

  .main .top-wrapper .actions {
    width: 240px;
    padding: 0;
    height: 40px;
    border: 1px solid #cccc;
    margin-top: 30px;
  }

  .main .top-wrapper .actions .search-input {
    max-width: 215px;
    border: 0;
  }
  .main .top-wrapper .actions .search-input {
    box-shadow: inset 0 -1px 0 #ddd;
    background-color: #ffffff;
  }

  .main .top-wrapper .section3 {
    /* width: 210px; */
    padding: 7px;
    padding-top: 30px;    
    padding-right: 20px;
    text-align: end;
  }

  .main .top-wrapper .section3 .locale-switch{
    display: inline;
  }

  .main .top-wrapper .section3 .list-of-farm{
    display: inline;
    margin-left: 10px;
  }

  .main .bottom-wrapper {
    color: white;
    display: flex;
    flex-direction: row;
    height: 70px;
  }

  .main .bottom-wrapper .nav-menu-block {
    background-color: #198352;
    color: white;
    font-size: 1rem;
    border: white;
    min-height: 65px;
    min-width: 150px;

    text-align: center;
    margin-left: 7px;
    margin-right: 7px;
    border-radius: 12px;
    max-width: 320px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .main .bottom-wrapper .nav-menu-block.medium{    
    max-width: 230px !important;
  }

  .main .bottom-wrapper .nav-menu-block:hover {
    /* background-color: rgb(47, 211, 85); */
    color: #ffffff;
  }

  .main .bottom-wrapper .nav-menu-block.item1 {
    min-width: 310px;
  }

  .main .bottom-wrapper .nav-menu-block.item2 {
    max-width: 220px;
  }

  .main .bottom-wrapper .nav-menu-block.item3 {
    max-width: 170px;
  }

  .main .bottom-wrapper .nav-menu-block.item4 {
    min-width: 120px;
  }

  .main .bottom-wrapper .nav-end-logo {
    max-height: 100px;
    align-self: center;
  }

  .main .bottom-wrapper .nav-end-logo.logo1 {
    width: 190px;
  }

  .main .nav-end-logo.logo1 img {
    width: 100%;
  }
}
