.container {
  margin: auto;
  text-align: center;
}

@media only screen and (min-width: 40rem) {
  .container {
    margin: auto;
    clear: both;
    width: 1024px;
  }
}
