.region-map-container {
  margin: 0;
  display: none;
  max-width: 800px;
}

.region-map-mobile {
  width: 90%;
  margin: 20px auto;
}

.map-img {
  visibility: hidden;
}

.region {
  fill: #edd9b6;
}

.tbilisi {
  fill: #edd9b6;
}

.region:hover {
  fill: green;
  cursor: pointer;
}

.region:hover,
.tbilisi:hover {
  fill: green;
  cursor: pointer;
}

.region-label {
  position: absolute;
  padding: 10px;
  background-color: rgb(250, 250, 250);
  
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 2px #ccc;
  z-index: 100000;
}

@media only screen and (min-width: 40rem) {
  .region-map-mobile {
    display: none;
  }
  .map-img {
    visibility: visible;
    margin: 0;
  }

  .region-map-container {
    margin: 0 auto;
    display: block;
    position: relative;
  }

  .region-map-main-title {
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    padding: 0;
    margin-top: 1em;
  }

  .region-map-image-wrapper {
    width: 1024px;
    margin: auto;
    position: relative;
    font-size: 1.05rem;
  }

  .region-map-image-wrapper .map-img {
    background-color: white;
    width: 1024px;
  }

  .region-map-region-abkhazia {
    top: 65px;
    left: 120px;
    display: inline-block;
    position: absolute;
    z-index: 300;
  }

  .region-map-region-svaneti {
    top: 110px;
    left: 337px;
    display: inline-block;
    position: absolute;
    z-index: 301;
  }
  .region-map-region-samegrelo {
    top: 230px;
    left: 265px;
    display: inline-block;
    position: absolute;
    z-index: 302;
  }
  .region-map-region-racha {
    top: 180px;
    left: 450px;
    display: inline-block;
    position: absolute;
    z-index: 303;
  }
  .region-map-region-guria {
    top: 321px;
    left: 296px;
    display: inline-block;
    position: absolute;
    z-index: 304;
  }
  .region-map-region-ajara {
    top: 385px;
    left: 290px;
    display: inline-block;
    position: absolute;
    z-index: 305;
  }
  .region-map-region-imereti {
    top: 285px;
    left: 405px;
    display: inline-block;
    position: absolute;
    z-index: 306;
  }
  .region-map-region-samtskhe-javakheti {
    top: 387px;
    left: 426px;
    display: inline-block;
    position: absolute;
    z-index: 307;
  }
  .region-map-region-samachablo {
    top: 248px;
    left: 578px;
    display: inline-block;
    position: absolute;
    z-index: 308;
  }
  .region-map-region-shida-kartli {
    top: 325px;
    left: 550px;
    display: inline-block;
    position: absolute;
    z-index: 309;
  }
  .region-map-region-kvemo-kartli {
    top: 438px;
    left: 620px;
    display: inline-block;
    position: absolute;
    z-index: 310;
  }
  .region-map-region-kazbegi {
    top: 185px;
    left: 653px;
    display: inline-block;
    position: absolute;
    z-index: 311;
  }
  .region-map-region-khevsureti {
    top: 202px;
    left: 713px;
    display: inline-block;
    position: absolute;
    z-index: 312;
  }
  .region-map-region-mtskheta-tianeti {
    top: 266px;
    left: 667px;
    display: inline-block;
    position: absolute;
    width: 100px;
    text-align: center;
    z-index: 313;
  }
  .region-map-region-tbilisi {
    top: 399px;
    left: 660px;
    display: inline-block;
    position: absolute;
    z-index: 314;
    font-weight: bold;
  }
  .region-map-region-tusheti {
    top: 230px;
    left: 805px;
    display: inline-block;
    position: absolute;
    z-index: 315;
  }
  .region-map-region-kakheti {
    top: 360px;
    left: 840px;
    display: inline-block;
    position: absolute;
    z-index: 316;
  }
}
